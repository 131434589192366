export default ($axios) => ({
  findFluxoAprovacao(siglaEmpresa) {
    return $axios.get(`/fluxosAprovacoes/${siglaEmpresa}`)
  },

  getFluxoAprovacaoById(siglaEmpresa, idFluxoAprovacao) {
    return $axios.get(`/fluxosAprovacoes/${siglaEmpresa}/${idFluxoAprovacao}`)
  },

  saveFluxoAprovacao(fluxoAprovacao) {
    return $axios.post(`/fluxosAprovacoes`, fluxoAprovacao)
  },

  updateFluxoAprovacao(fluxoAprovacao) {
    return $axios.put(`/fluxosAprovacoes`, fluxoAprovacao)
  },

  removeGrupoUsuario(
    siglaEmpresa,
    idFluxoAprovacao,
    idGrupoUsuario,
    sequencia
  ) {
    return $axios.delete(
      `/fluxosAprovacoes/${siglaEmpresa}/${idFluxoAprovacao}/${idGrupoUsuario}/${sequencia}`
    )
  },

  deleteFluxoAprovacao(siglaEmprea, idFluxoAprovacao) {
    return $axios.delete(`/fluxosAprovacoes/${siglaEmprea}/${idFluxoAprovacao}`)
  },
})
