import fichasRacService from '../services/ambiental/fichasRac.service'

// Base
import coreService from '~/services/core.service'
import empresaService from '~/services/empresa.service'
import usuarioService from '~/services/usuario.service'
import gedService from '~/services/ged.service'
import resumoService from '~/services/resumo.service'

// Cadastros
import fluxosCaixaService from '~/services/cadastros/fluxosCaixa.service'
import cidadeService from '~/services/cadastros/cidade.service'
import obraService from '~/services/cadastros/obra.service'
import rodoviaService from '~/services/cadastros/rodovia.service'
import temaService from '~/services/cadastros/tema.service'
import tipoDocumentoService from '~/services/cadastros/tipoDocumento.service'

import entidadeService from '~/services/cadastros/entidade.service'
import poderConcedenteService from '~/services/poderConcedente.service'

import relacoesInstitucionaisService from '~/services/relacoesInstitucionais.service'

import demandasService from '~/services/demandas.service'
import fornecedoresService from '~/services/cadastros/fornecedores.service'

import indiceReajusteService from '~/services/cadastros/indiceReajuste.service'

// Correspondências
import correspondenciaService from '~/services/correspondencia.service'

// Ativos
import parametroService from '~/services/ativos/parametro.service'
import categoriaAtivoService from '~/services/ativos/categoriaAtivo.service'
import itemAtivoService from '~/services/ativos/itemAtivo.service'
import monitoracaoService from '~/services/ativos/monitoracao.service'
import unidadeMedidaService from '~/services/ativos/unidadeMedida.service'
import precoUnitarioService from '~/services/ativos/precoUnitario.service'
import cicloMonitoracaoService from '~/services/ativos/cicloMonitoracao.service'
import eventoAtivoService from '~/services/ativos/eventoAtivo.service'
import tipoEventoService from '~/services/ativos/tipoEvento.service'
import historicoIntervencaoService from '~/services/ativos/historicoIntervencao.service'
import estacaoService from '~/services/ativos/estacoes.service'

// Ambiental
import orgaosAprovadoresService from '~/services/ambiental/orgaosAprovadores.service'
import pareceresAutorizativosService from '~/services/ambiental/pareceresAutorizativos.service'
import tipoObraService from '~/services/ambiental/tipoObra.service'
import licencaService from '~/services/ambiental/licenca.service'
import analiseAmbientalService from '~/services/ambiental/analiseAmbiental.service'
import analiseGrupoService from '~/services/ambiental/analiseGrupo.service'
import analiseTipoService from '~/services/ambiental/analiseTipo.service'
import areaProtegidaService from '~/services/ambiental/areaProtegida.service'
import areaEspecialmenteProtegidaService from '~/services/ambiental/areaEspecialmenteProtegida.service'
import autorizacaoRapService from '~/services/ambiental/autorizacaoRap.service'
import autorizacaoIntervencaoService from '~/services/ambiental/autorizacaoIntervencao.service'
import boasPraticasService from '~/services/ambiental/boasPraticas.service'
import checkListService from '~/services/ambiental/checklist.service'
import depositoService from '~/services/ambiental/deposito.service'
import equipeService from '~/services/ambiental/equipe.service'
import gravidadeService from '~/services/ambiental/gravidade.service'
import incidenteService from '~/services/ambiental/incidente.service'
import inspecaoAmbientalService from '~/services/ambiental/inspecaoAmbiental.service'
import fichasInspecaoService from '~/services/ambiental/fichasInspecaoAmbiental.service'
import ocorrenciaService from '~/services/ambiental/ocorrencias.service'
import pontoControleService from '~/services/ambiental/pontoControle.service'
import eixoEstrategicoService from '~/services/ambiental/eixoEstrategico.service'
import linhasNegocioService from '~/services/ambiental/linhasNegocio.service'
import statusAprovacoesUsoService from '~/services/ambiental/statusAprovacoesUso.service'
import statusAprovacaoAgenciaService from '~/services/ambiental/statusAprovacaoAgencia.service'
import materiaisApoioService from '~/services/ambiental/materiaisApoio.service'
import statusUtilizacaoService from '~/services/ambiental/statusUtilizacao.service'
import situacaoAgenciaService from '~/services/ambiental/situacaoAgencia.service'
import tipoRequisitoLegalService from '~/services/ambiental/tipoRequisitoLegal.service'
import grauRiscoService from '~/services/ambiental/grauRisco.service'
import racService from '~/services/ambiental/rac.service'
import racomService from '~/services/ambiental/racom.service'
import regraOuroService from '~/services/ambiental/regraOuro.service'
import requisitoLegalService from '~/services/ambiental/requisitoLegal.service'
import solucoesService from '~/services/ambiental/solucoes.service'
import statusFichaService from '~/services/ambiental/statusFicha.service'
import tipoInspecaoAmbientalService from '~/services/ambiental/tipoInspecaoAmbiental.service'
import tipoLicencaAmbientalService from '~/services/ambiental/tipoLicencaAmbiental.service'
import tipoFichaService from '~/services/ambiental/tipoFicha.service'
import tipoPontoControleService from '~/services/ambiental/tipoPontoControle.service'
import fluxoAprovacaoService from '~/services/ambiental/fluxoAprovacao.service'
import ocorrenciaFlorestalService from '~/services/ambiental/ocorrenciaFlorestal.service'
import elementoArboreoService from '~/services/ambiental/elementoAboreo.service'
import impactoAmbientalService from '~/services/ambiental/impactoAmbiental.service'
import iqmasService from '~/services/ambiental/iqmas.service'
import inspecaoFlorestalService from '~/services/ambiental/inspecaoFlorestal.service'

// Notificacao
import notificacaoService from '~/services/notificacao.service'

// Laboratório
import parametrosEnsaiosService from '~/services/laboratorio/cadastros/parametrosEnsaios.service'
import peneirasService from '~/services/laboratorio/cadastros/peneiras.service'
import peneirasItemService from '~/services/laboratorio/cadastros/peneirasItem.service'
import liganteService from '~/services/laboratorio/cadastros/ligantes.service'
import ensaioService from '~/services/laboratorio/ensaios/ensaio.service'
import projetosService from '~/services/laboratorio/cadastros/projetos.service'
import planosAuditoriaService from '~/services/laboratorio/cadastros/planosAuditoria.service'
import controlesLabService from '~/services/laboratorio/controles.service'

import fichasService from '~/services/laboratorio/fichas.service'
import grupoServicoService from '~/services/laboratorio/cadastros/grupoServico.service'

// Sigaco
import elementoSigacoService from '~/services/sigaco/elemento.service'
import faseMonitoracaoService from '~/services/sigaco/faseMonitoracao.service'
import tipoElementoService from '~/services/sigaco/tipoElemento.service'
import monitoracaoSigacoService from '~/services/sigaco/monitoracaoSigaco.service'
import planoAcaoService from '~/services/sigaco/planoAcao.service'

// Pleitos
import pleitosService from '~/services/pleitos.service'

// Obrigações
import obrigacaoService from '~/services/obrigacao.service'
import itensContratuaisService from '~/services/contrato/itensContratuais.service'

// Geral
import mapaService from '~/services/mapa.service'
import calendarioService from '~/services/calendario.service'
import downloadService from '~/services/download.service'

// Conservação
// import elementoService from '~/services/conservacao/elemento.service'
// import equipeService from '~/services/conservacao/equipe.service'
// import grupoServicoService from '~/services/conservacao/grupoServico.service'
// import inventarioService from '~/services/conservacao/inventario.service'
// import parametroService from '~/services/conservacao/parametro.service'
// import previstoService from '~/services/conservacao/previsto.service'
// import realizadoService from '~/services/conservacao/realizado.service'
// import rodoviaSegmentoService from '~/services/conservacao/rodoviaSegmento.service'
// import servicoService from '~/services/conservacao/servico.service'

// Segurança
import segurancaService from '~/services/seguranca/seguranca.service'

export default function ({ $axios }, inject) {
  // Base
  inject('coreService', coreService($axios))
  inject('empresaService', empresaService($axios))
  inject('usuarioService', usuarioService($axios))
  inject('gedService', gedService($axios))
  inject('resumoService', resumoService($axios))

  inject('fluxosCaixaService', fluxosCaixaService($axios))
  inject('cidadeService', cidadeService($axios))
  inject('entidadeService', entidadeService($axios))
  inject('poderConcedenteService', poderConcedenteService($axios))
  inject('rodoviaService', rodoviaService($axios))
  inject('relacoesInstitucionaisService', relacoesInstitucionaisService($axios))
  inject('demandasService', demandasService($axios))
  inject('temaService', temaService($axios))
  inject('tipoDocumentoService', tipoDocumentoService($axios))
  inject('fornecedoresService', fornecedoresService($axios))
  inject('indiceReajusteService', indiceReajusteService($axios))
  // Correspondências
  inject('correspondenciaService', correspondenciaService($axios))
  // Notificacao
  inject('notificacaoService', notificacaoService($axios))

  // Ativos
  inject('parametroService', parametroService($axios))
  inject('categoriaAtivoService', categoriaAtivoService($axios))
  inject('itemAtivoService', itemAtivoService($axios))
  inject('monitoracaoService', monitoracaoService($axios))
  inject('unidadeMedidaService', unidadeMedidaService($axios))
  inject('precoUnitarioService', precoUnitarioService($axios))
  inject('cicloMonitoracaoService', cicloMonitoracaoService($axios))
  inject('eventoAtivoService', eventoAtivoService($axios))
  inject('tipoEventoService', tipoEventoService($axios))
  inject('historicoIntervencaoService', historicoIntervencaoService($axios))
  inject('estacaoService', estacaoService($axios))

  // Ambiental
  inject('orgaosAprovadoresService', orgaosAprovadoresService($axios))
  inject('pareceresAutorizativosService', pareceresAutorizativosService($axios))
  inject('analiseAmbientalService', analiseAmbientalService($axios))
  inject('analiseGrupoService', analiseGrupoService($axios))
  inject('analiseTipoService', analiseTipoService($axios))
  inject('areaProtegidaService', areaProtegidaService($axios))
  inject(
    'areaEspecialmenteProtegidaService',
    areaEspecialmenteProtegidaService($axios)
  )
  inject('autorizacaoRapService', autorizacaoRapService($axios))
  inject('autorizacaoIntervencaoService', autorizacaoIntervencaoService($axios))

  inject('boasPraticasService', boasPraticasService($axios))

  inject('checkListService', checkListService($axios))
  inject('cidadeService', cidadeService($axios))
  inject('depositoService', depositoService($axios))
  inject('empresaService', empresaService($axios))
  inject('equipeService', equipeService($axios))

  inject('fichasRacService', fichasRacService($axios))

  inject('gravidadeService', gravidadeService($axios))
  inject('incidenteService', incidenteService($axios))
  inject('inspecaoAmbientalService', inspecaoAmbientalService($axios))
  inject('fichasInspecaoService', fichasInspecaoService($axios))
  inject('licencaService', licencaService($axios))

  inject('obraService', obraService($axios))
  inject('ocorrenciaService', ocorrenciaService($axios))
  inject('eixoEstrategicoService', eixoEstrategicoService($axios))
  inject('linhasNegocioService', linhasNegocioService($axios))
  inject('tipoRequisitoLegalService', tipoRequisitoLegalService($axios))
  inject('statusAprovacoesUsoService', statusAprovacoesUsoService($axios))
  inject('statusAprovacaoAgenciaService', statusAprovacaoAgenciaService($axios))
  inject('materiaisApoioService', materiaisApoioService($axios))

  inject('statusUtilizacaoService', statusUtilizacaoService($axios))
  inject('situacaoAgenciaService', situacaoAgenciaService($axios))
  inject('grauRiscoService', grauRiscoService($axios))

  inject('pontoControleService', pontoControleService($axios))
  inject('racService', racService($axios))
  inject('racomService', racomService($axios))

  inject('regraOuroService', regraOuroService($axios))
  inject('requisitoLegalService', requisitoLegalService($axios))

  inject('rodoviaService', rodoviaService($axios))
  inject('solucoesService', solucoesService($axios))
  inject('statusFichaService', statusFichaService($axios))
  inject('tipoInspecaoAmbientalService', tipoInspecaoAmbientalService($axios))
  inject('tipoLicencaAmbientalService', tipoLicencaAmbientalService($axios))
  inject('tipoObraService', tipoObraService($axios))
  inject('tipoFichaService', tipoFichaService($axios))
  inject('tipoPontoControleService', tipoPontoControleService($axios))
  inject('fluxoAprovacaoService', fluxoAprovacaoService($axios))
  inject('ocorrenciaFlorestalService', ocorrenciaFlorestalService($axios))
  inject('elementoArboreoService', elementoArboreoService($axios))
  inject('impactoAmbientalService', impactoAmbientalService($axios))
  inject('iqmasService', iqmasService($axios))
  inject('inspecaoFlorestalService', inspecaoFlorestalService($axios))

  // Laboratório
  inject('parametrosEnsaiosService', parametrosEnsaiosService($axios))
  inject('peneirasService', peneirasService($axios))
  inject('peneiraItemService', peneirasItemService($axios))
  inject('liganteService', liganteService($axios))
  inject('ensaioService', ensaioService($axios))
  inject('projetosService', projetosService($axios))
  inject('planosAuditoriaService', planosAuditoriaService($axios))
  inject('controlesLabService', controlesLabService($axios))

  inject('fichasService', fichasService($axios))
  inject('grupoServicoService', grupoServicoService($axios))

  // Sigaco
  inject('elementoSigacoService', elementoSigacoService($axios))
  inject('faseMonitoracaoService', faseMonitoracaoService($axios))
  inject('tipoElementoService', tipoElementoService($axios))
  inject('monitoracaoSigacoService', monitoracaoSigacoService($axios))
  inject('planoAcaoService', planoAcaoService($axios))

  // Geral
  inject('mapaService', mapaService($axios))
  inject('calendarioService', calendarioService($axios))
  inject('downloadService', downloadService($axios))

  // Segurança

  inject('segurancaService', segurancaService($axios))

  // Pleito
  inject('pleitosService', pleitosService($axios))

  // Obrigações
  inject('obrigacaoService', obrigacaoService($axios))
  inject('itensContratuaisService', itensContratuaisService($axios))

  // Conservação
  // inject('elementoService', elementoService($axios))
  // inject('equipeService', equipeService($axios))
  // inject('grupoServicoService', grupoServicoService($axios))
  // inject('inventarioService', inventarioService($axios))
  // inject('parametroService', parametroService($axios))
  // inject('previstoService', previstoService($axios))
  // inject('realizadoService', realizadoService($axios))
  // inject('rodoviaSegmentoService', rodoviaSegmentoService($axios))
  // inject('servicoService', servicoService($axios))
}
