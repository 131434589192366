export default ($axios) => ({
  findRacs(
    siglaEmpresa,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    $axios.setHeader('Content-Type', 'application/json')
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'id.idRac'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection

    return $axios.post(
      `/racs/${siglaEmpresa}/filtros?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
      payload
    )
  },

  getRacById(siglaEmpresa, idRac) {
    return $axios.get(`/racs/${siglaEmpresa}/${idRac}`)
  },

  saveRac(
    siglaEmpresa,
    rac,
    ocorrencias, correcoes, anexos, regrasOuro, requisitosLegais, licencas 
  ) {
    $axios.setHeader('Content-Type', 'application/json')
    const formData = new FormData()

    rac.status = null
    rac.evento = null
    rac.cidade = null
    rac.empresa = null
    rac.gravidade = null
    rac.tipoRac = null
    rac.obra = null
    rac.rodovia = null
    rac.coordenadorObra = null
    rac.equipeConcessao = null
    rac.equipeEmpreiteira = null
    rac.equipeAmbiental = null
    rac.fichas = null

    if (rac !== undefined) {
      formData.append(
        'rac',
        JSON.stringify(rac, (key, value) => {
          if (value !== null) return value
        })
      )
    }

    if (ocorrencias !== undefined) {
      // console.log('Ocorrências=>', ocorrencias)
      formData.append('ocorrencias', JSON.stringify(ocorrencias))
    }

    if (correcoes !== undefined) {
      // console.log('Correcoes=>', correcoes)
      formData.append('correcoes', JSON.stringify(correcoes))
    }

    if (anexos !== undefined) {
      console.log('Anexos=>', anexos)
      const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('filesAnexos', anexo.file)
        anexosRaw.push({
          comentario: anexo.comentario,
          anexo: {
            nome: anexo.file.name,
          },
        })
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }

    if (regrasOuro !== undefined)
      formData.append('regrasOuro', JSON.stringify(regrasOuro))

    if (requisitosLegais !== undefined)
      formData.append('requisitosLegais', JSON.stringify(requisitosLegais))

    if (licencas !== undefined)
      formData.append('licencas', JSON.stringify(licencas))

    window.console.log('FORMDATA => ', formData)
    return $axios.post(`/racs/${siglaEmpresa}`, formData)
  },

  saveMultiplasRacs(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.put(
      `/racs/${siglaEmpresa}/multiplas`,
      JSON.stringify(payload)
    )
  },

  exportacoes(siglaEmpresa, tipoFicha, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }
    return $axios.post(
      `/ambientais/fichas/${siglaEmpresa}/exportacoes/${tipoFicha}`,
      payload,
      requestCfg
    )
  },

  deleteRac(siglaEmpresa, idRac) {
    return $axios.delete(`/racs/${siglaEmpresa}/${idRac}`)
  },

  createAnexo(siglaEmpresa, idRac, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(`/racs/${siglaEmpresa}/${idRac}/anexos`, formData)
  },

  findAnexos(siglaEmpresa, idRac) {
    return $axios.get(`/racs/${siglaEmpresa}/${idRac}/anexos`)
  },

  deleteAnexo(siglaEmpresa, idRac, seqAnexo) {
    return $axios.delete(`/racs/${siglaEmpresa}/${idRac}/anexos/${seqAnexo}`)
  },

  editComentarioAnexo(siglaEmpresa, idRac, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/racs/${siglaEmpresa}/${idRac}/anexos/comentarios`,
      payload
    )
  },

  saveCorrecoesRac(payload, siglaEmpresa, idRac) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(`/racs/${siglaEmpresa}/${idRac}/correcoes`, payload)
  },

  findCorrecoesRac(siglaEmpresa, idRac) {
    return $axios.get(`/racs/${siglaEmpresa}/${idRac}/correcoes`)
  },

  deleteCorrecao(siglaEmpresa, idRac, seqCorrecao) {
    return $axios.delete(
      `/racs/${siglaEmpresa}/${idRac}/correcoes/${seqCorrecao}`
    )
  },

  findOcorrenciasRac(siglaEmpresa, idRac) {
    return $axios.get(`/racs/${siglaEmpresa}/${idRac}/ocorrencias`)
  },

  findRequisitosLegaisRac(siglaEmpresa, idRac) {
    return $axios.get(`/racs/${siglaEmpresa}/${idRac}/requisitosLegais`)
  },

  findHistoricosRac(siglaEmpresa, idRac) {
    return $axios.get(`/racs/${siglaEmpresa}/${idRac}/historicos`)
  },

  saveHistoricoRac(siglaEmpresa, idRac, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/racs/${siglaEmpresa}/${idRac}/historicos?descricao=${payload}`
    )
  },

  findAprovacoesRac(siglaEmpresa, idRac) {
    console.log('findAprovacoesRac=>', siglaEmpresa, idRac)
    return $axios.get(`/racs/${siglaEmpresa}/${idRac}/aprovacoes`)
  },

  downloadExcel(empresa, filter) {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.post(`/racs/${empresa}/exportacaoExcel`, filter, requestCfg)
  },

  downloadPdf(empresa, idRac, tipoMapa = 'S') {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.post(
      `/racs/${empresa}/${idRac}/exportacaoPdf?tipoMapa=${tipoMapa}`,
      {},
      requestCfg
    )
  },

  executeEvento(empresa, idRac, tipoEvento, paramsPayload = {}) {
    $axios.setHeader('Content-Type', 'application/json')
    const { seqAprovacao, justificativa, perfilReprovar } = paramsPayload

    let parms = ''

    if (Object.keys(paramsPayload).length > 0) {
      if (seqAprovacao !== undefined) parms += '&seqAprovacao=' + seqAprovacao
      if (justificativa !== undefined) parms += '&descricao=' + justificativa
      if (perfilReprovar !== undefined)
        parms += '&perfilReprovar=' + perfilReprovar

      parms = '?' + parms.slice(1)
    }

    return $axios.post(
      `/racs/${empresa}/${idRac}/eventos/${tipoEvento}${parms}`
    )
  },

  findLogs(empresa, datInicial, datFinal) {
    return $axios.get(
      `/racs/${empresa}/logs?datInicial=${datInicial}&datFinal=${datFinal}`
    )
  },

  findLicencas(empresa, idRac) {
    return $axios.get(`/racs/${empresa}/${idRac}/licencas`)
  },

  saveLicencas(empresa, idRac, payload) {
    return $axios.put(`/racs/${empresa}/${idRac}/licencas`, payload)
  },

  deleteLicenca(empresa, idRac, idLicenca) {
    return $axios.delete(`/racs/${empresa}/${idRac}/licencas/${idLicenca}`)
  },
})
