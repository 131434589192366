export default ($axios) => ({
  find(siglaEmpresa) {
    return $axios.get(`/indicesReajuste/${siglaEmpresa}/indicadores`)
  },

  getIndicadoresById(siglaEmpresa, idIndice) {
    return $axios.get(
      `/indicesReajuste/${siglaEmpresa}/indicadores/${idIndice}`
    )
  },

  saveIndicador(siglaEmpresa, body) {
    return $axios.post(`/indicesReajuste/${siglaEmpresa}/indicadores`, body)
  },

  deleteIndicador(siglaEmpresa, idIndice) {
    return $axios.delete(
      `/indicesReajuste/${siglaEmpresa}/indicadores/${idIndice}`
    )
  },
})
