function getSearchUrlFromFiltro(filtro) {
  let params = ''

  if (filtro === undefined) return ''

  Object.entries(filtro).forEach(([key, value]) => {
    if (value === undefined || value === null || value === '') return
    if (Array.isArray(filtro[key]) && filtro[key].length === 0) return
    console.log(filtro[key], filtro)
    if (params !== '') params += '&'
    params += `${key}=${value}`
  })

  if (params !== '')
    params = '?' + params;

  return params
}

export default ($axios) => ({
  uploadFicha(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(`/ensaios/${siglaEmpresa}/fichas/upload`, payload)
  },
  findLigantes(siglaEmpresa, filtro) {
    const searchUrl = getSearchUrlFromFiltro(filtro)

    return $axios.get(`/ensaios/${siglaEmpresa}/ligantes${searchUrl}`)
  },

  saveLigante(siglaEmpresa, ligante) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/ligantes`, ligante)
  },

  getEnsaioLiganteById(siglaEmpresa, idLigante) {
    return $axios.get(`/ensaios/${siglaEmpresa}/ligantes/${idLigante}`)
  },

  findMisturas(siglaEmpresa, filtro) {
    const searchUrl = getSearchUrlFromFiltro(filtro)

    return $axios.get(`/ensaios/${siglaEmpresa}/misturas${searchUrl}`)
  },

  findGeral(siglaEmpresa, filtro) {
    const searchUrl = getSearchUrlFromFiltro(filtro)

    return $axios.get(`/ensaios/${siglaEmpresa}/geral${searchUrl}`)
  },

  findSolos(siglaEmpresa, filtro) {
    const searchUrl = getSearchUrlFromFiltro(filtro)

    return $axios.get(`/ensaios/${siglaEmpresa}/solos${searchUrl}`)
  },

  findConcreto(siglaEmpresa, filtro) {
    const searchUrl = getSearchUrlFromFiltro(filtro)

    return $axios.get(`/ensaios/${siglaEmpresa}/concretos${searchUrl}`)
  },

  saveTeorGranulometria(siglaEmpresa, ligante) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/teorGranulometria`, ligante)
  },

  getTeorGranulometriaById(siglaEmpresa, idTeor) {
    return $axios.get(`/ensaios/${siglaEmpresa}/teorGranulometria/${idTeor}`)
  },

  saveControleCompactacao(siglaEmpresa, controle) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/compactacaoEspessura`,
      controle
    )
  },

  getControleCompactacaoById(siglaEmpresa, idControle) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/compactacaoEspessura/${idControle}`
    )
  },

  saveCompactacaoSolo(siglaEmpresa, controle) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/compactacaoSolo`, controle)
  },

  getCompactacaoSoloById(siglaEmpresa, idControle) {
    return $axios.get(`/ensaios/${siglaEmpresa}/compactacaoSolo/${idControle}`)
  },

  saveResistenciaTracao(siglaEmpresa, resistencia) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/resistenciaTracao`,
      resistencia
    )
  },

  getResistenciaTracaoById(siglaEmpresa, idResistencia) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/resistenciaTracao/${idResistencia}`
    )
  },

  saveDanosUmidade(siglaEmpresa, danos) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/umidadeInduzida`, danos)
  },

  getDanosUmidadeById(siglaEmpresa, idDanos) {
    return $axios.get(`/ensaios/${siglaEmpresa}/umidadeInduzida/${idDanos}`)
  },

  saveTemperaturaInsumo(siglaEmpresa, temperatura) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/temperaturaInsumo`,
      temperatura
    )
  },

  getTemperaturaInsumoById(siglaEmpresa, idTemperatura) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/temperaturaInsumo/${idTemperatura}`
    )
  },

  saveExecucaoMistura(siglaEmpresa, execucao) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/execucaoMistura`, execucao)
  },

  getExecucaoMisturaById(siglaEmpresa, idExecucao) {
    return $axios.get(`/ensaios/${siglaEmpresa}/execucaoMistura/${idExecucao}`)
  },

  saveVigaBenkelman(siglaEmpresa, viga) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/vigaBenkelman`, viga)
  },

  getVigaBenkelmanById(siglaEmpresa, idViga) {
    return $axios.get(`/ensaios/${siglaEmpresa}/vigaBenkelman/${idViga}`)
  },

  saveMacrotextura(siglaEmpresa, macrotextura) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/manchaAreia`, macrotextura)
  },

  getMacrotexturaById(siglaEmpresa, idMacrotextura) {
    return $axios.get(`/ensaios/${siglaEmpresa}/manchaAreia/${idMacrotextura}`)
  },

  saveMicrotextura(siglaEmpresa, microtextura) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/resistenciaDerrapagem`,
      microtextura
    )
  },

  getMicrotexturaById(siglaEmpresa, idMicrotextura) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/resistenciaDerrapagem/${idMicrotextura}`
    )
  },

  saveCaracterizacaoSolo(siglaEmpresa, caracterizacao) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/caracterizacaoSolo`,
      caracterizacao
    )
  },

  getCaracterizacaoSoloById(siglaEmpresa, idCaracterizacao) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/caracterizacaoSolo/${idCaracterizacao}`
    )
  },

  saveEnsaioCompactacao(siglaEmpresa, compactacao) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/compactacao`, compactacao)
  },

  getEnsaioCompactacaoById(siglaEmpresa, idCompactacao) {
    return $axios.get(`/ensaios/${siglaEmpresa}/compactacao/${idCompactacao}`)
  },

  saveResistenciaCompressao(siglaEmpresa, compactacao) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/compressaoAxial`, compactacao)
  },

  getResistenciaCompressaoById(siglaEmpresa, idResistencia) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/compressaoAxial/${idResistencia}`
    )
  },

  saveResistenciaFlexao(siglaEmpresa, compactacao) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/tracaoFlexao`, compactacao)
  },

  getResistenciaFlexaoById(siglaEmpresa, idResistencia) {
    return $axios.get(`/ensaios/${siglaEmpresa}/tracaoFlexao/${idResistencia}`)
  },

  saveEquivalenteAreia(siglaEmpresa, equivalente) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/equivalenteAreia`, equivalente)
  },

  getEquivalenteAreiaById(siglaEmpresa, idEquivalente) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/equivalenteAreia/${idEquivalente}`
    )
  },

  saveAnaliseGranulometrica(siglaEmpresa, analise) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/analiseGranulometrica`,
      analise
    )
  },

  getAnaliseGranulometricaById(siglaEmpresa, idAnalise) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/analiseGranulometrica/${idAnalise}`
    )
  },

  saveDensidadeReal(siglaEmpresa, densidade) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/densidadeRealAbsorcao`,
      densidade
    )
  },

  getDensidadeRealById(siglaEmpresa, idDensidade) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/densidadeRealAbsorcao/${idDensidade}`
    )
  },

  saveIndiceFormaCrivos(siglaEmpresa, crivo) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/crivo`, crivo)
  },

  getIndiceFormaCrivosById(siglaEmpresa, idCrivo) {
    return $axios.get(`/ensaios/${siglaEmpresa}/crivo/${idCrivo}`)
  },

  saveIndiceFormaPaquimetro(siglaEmpresa, parquimetro) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/parquimetro`, parquimetro)
  },

  getIndiceFormaPaquimetroById(siglaEmpresa, idParquimetro) {
    return $axios.get(`/ensaios/${siglaEmpresa}/parquimetro/${idParquimetro}`)
  },

  saveIndiceLamelaridade(siglaEmpresa, lamelaridade) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/lamelaridade`, lamelaridade)
  },

  getIndiceLamelaridadeById(siglaEmpresa, idLamelaridade) {
    return $axios.get(`/ensaios/${siglaEmpresa}/lamelaridade/${idLamelaridade}`)
  },

  saveTeorMicro(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/teorMicro`, payload)
  },

  getTeorMicroById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/teorMicro/${id}`)
  },

  saveMicroesferaVidro(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/microesferaVidro`, payload)
  },

  getMicroesferaVidroById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/microesferaVidro/${id}`)
  },

  saveAnaliseTinta(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/demarcacaoViaria`, payload)
  },

  getAnaliseTintaById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/demarcacaoViaria/${id}`)
  },

  saveLiganteEmulsao(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/ensaios/${siglaEmpresa}/caracterizacaoLiganteBetuminoso`,
      payload
    )
  },

  getLiganteEmulsaoById(siglaEmpresa, id) {
    return $axios.get(
      `/ensaios/${siglaEmpresa}/caracterizacaoLiganteBetuminoso/${id}`
    )
  },

  saveMerlin(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/medicaoMerlin`, payload)
  },

  getMerlinById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/medicaoMerlin/${id}`)
  },

  saveControleDensimetro(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/grauCompactacao`, payload)
  },

  getControleDensimetroById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/grauCompactacao/${id}`)
  },

  saveTaxaPintura(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/pinturaLigacao`, payload)
  },

  getTaxaPinturaById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/pinturaLigacao/${id}`)
  },

  saveUmidadeNatural(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/umidadeNatural`, payload)
  },

  getUmidadeNaturalById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/umidadeNatural/${id}`)
  },

  saveBoletimSondagem(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/boletimSondagem`, payload)
  },

  getBoletimSondagemById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/boletimSondagem/${id}`)
  },

  saveDensidadeMaxima(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/densidadeMaxima`, payload)
  },

  getDensidadeMaximaById(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/densidadeMaxima/${id}`)
  },

  completedEnsaio(siglaEmpresa, payload) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/ensaios/${siglaEmpresa}/concluir`, payload)
  },

  deleteEnsaio(siglaEmpresa, idEnsaio) {
    $axios.setHeader('Content-Type', 'application/json')
    
    return $axios.delete(`/ensaios/${siglaEmpresa}/${idEnsaio}`)
  },

  findHistorico(siglaEmpresa, id) {
    return $axios.get(`/ensaios/${siglaEmpresa}/historicos/${id}`)
  },

  downloadPlanilha(siglaEmpresa, grupoEnsaio, sortBy, sortDesc, filtro) {
    let searchUrl = getSearchUrlFromFiltro(filtro)

    const requestCfg = {
      responseType: 'arraybuffer',
    }

    if (sortBy === '')
      sortBy = 'numero'

    if (searchUrl === '')
      searchUrl = `?sortBy=${sortBy}&sortDesc=${sortDesc}`
    else
      searchUrl = searchUrl + `&sortBy=${sortBy}&sortDesc=${sortDesc}`

    return $axios.get(
      `/ensaios/${siglaEmpresa}/${grupoEnsaio}/exportacao${searchUrl}`,
      requestCfg
    )
  },

  // Dashboard
  findDadosDashboard(siglaEmpresa, filtro) {
    let parms = ''
    let sizeEmpresa = 0
    let sizeRodovias = 0

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue

          // Empresa
          if (key === 'unidades') {
            if (filtro.unidades.length > 0) {
              if (filtro.unidades.length > sizeEmpresa) {
                for (const empresa of filtro.unidades) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + empresa
                  sizeEmpresa++
                }
              }
            }
          }

          // Rodovias
          if (key === 'rodovias') {
            if (filtro.rodovias.length > 0) {
              if (filtro.rodovias.length > sizeRodovias) {
                for (const rodovia of filtro.rodovias) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + rodovia
                  sizeRodovias++
                }
              }
            }
          }

          if (key !== 'unidades' && key !== 'rodovias') {
            if (filtro[key] !== null) {
              if (parms !== '') parms += '&'
              parms += key + '=' + filtro[key]
            }
          }
        }
      }

      if (parms !== '') parms = '?' + parms
    }

    return $axios.get(`/ensaios/${siglaEmpresa}/dashboards${parms}`)
  },

  countProjetos(empresa, idProjeto) {
    return $axios.get(`/ensaios/${empresa}/projetos/${idProjeto}/count`)
  },

  // Exportar planilha

  downloadEnsaio(siglaEmpresa, idEnsaio, nomeFicha, subTipo) {
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    let parms = ''
    if (subTipo !== undefined) parms = '&subTipo=' + subTipo

    return $axios.get(
      `/ensaios/${siglaEmpresa}/fichas/download/${idEnsaio}?nomeFicha=${nomeFicha}${parms}`,
      requestCfg
    )
  },

  exportDashboardPdf(empresa, filtro, payload) {
    let params = ''
    console.log(filtro)
    Object.entries(filtro).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        if (Array.isArray(value)) {
          if (value.length === 0) return

          value.forEach((item) => {
            if (params !== '') params += '&'
            params += key + '=' + item
          })
        } else {
          if (params !== '') params += '&'
          params += key + '=' + value
        }
      }
    })
    if (params !== '') params = '?' + params

    console.log(params)

    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/ensaios/${empresa}/dashboards/exportPdf${params}`,
      payload,
      { responseType: 'blob' }
    )
  },

})
