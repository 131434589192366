const URL_API = '/monitoracoes/sigaco'

export default ($axios) => ({
  findMonitoracoes(siglaEmpresa, tipoAtivo, filtro) {
    let parms = ''
    let sizeFase = 0

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue

          // Fase Monitoração
          if (key === 'faseMonitoracao') {
            if (filtro.faseMonitoracao.length > 0) {
              if (filtro.faseMonitoracao.length > sizeFase) {
                for (const fase of filtro.faseMonitoracao) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + fase
                  sizeFase++
                }
              }
            }
          }

          if (key !== 'faseMonitoracao') {
            if (filtro[key] !== null) {
              if (parms !== '') parms += '&'
              parms += key + '=' + filtro[key]
            }
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`${URL_API}/${siglaEmpresa}/${tipoAtivo}/all${parms}`)
  },

  findMonitoracoesParametro(siglaEmpresa, tipo) {
    return $axios.get(`${URL_API}/${siglaEmpresa}/${tipo}`)
  },

  getMonitoracaoById(siglaEmpresa, tipoAtivo, idMonitoracao) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/${tipoAtivo}/${idMonitoracao}`
    )
  },

  saveMonitoracao(siglaEmpresa, monitoracao) {
    if (monitoracao.tipoAtivo === 'OAC')
      return this.saveMonitoracaoDrenagem(siglaEmpresa, monitoracao)

    if (monitoracao.tipoAtivo === 'SNH')
      return this.saveMonitoracaoSinalizacaoHorizontal(
        siglaEmpresa,
        monitoracao
      )

    if (monitoracao.tipoAtivo === 'SNV')
      return this.saveMonitoracaoSinalizacaoVertical(siglaEmpresa, monitoracao)

    if (monitoracao.tipoAtivo === 'TEC')
      return this.saveMonitoracaoTerrapleno(siglaEmpresa, monitoracao)
  },

  saveMonitoracaoDrenagem(siglaEmpresa, monitoracao) {
    return $axios.post(`${URL_API}/${siglaEmpresa}/drenagens`, monitoracao)
  },

  saveMonitoracaoSinalizacaoHorizontal(siglaEmpresa, monitoracao) {
    return $axios.post(
      `${URL_API}/${siglaEmpresa}/sinalizacoesHorizontais`,
      monitoracao
    )
  },

  saveMonitoracaoSinalizacaoVertical(siglaEmpresa, monitoracao) {
    return $axios.post(
      `${URL_API}/${siglaEmpresa}/sinalizacoesVerticais`,
      monitoracao
    )
  },

  saveMonitoracaoTerrapleno(siglaEmpresa, monitoracao) {
    return $axios.post(`${URL_API}/${siglaEmpresa}/terrapleno`, monitoracao)
  },

  deleteMonitoracao(siglaEmpresa, tipoAtivo, idMonitoracao) {
    return $axios.delete(
      `${URL_API}/${siglaEmpresa}/${tipoAtivo}/${idMonitoracao}`
    )
  },

  deleteImportacoes(empresa, fase, importacao) {
    return $axios.delete(
      `${URL_API}/importacoes/${empresa}/${fase}/${importacao}/excluir`
    )
  },

  findImagens(siglaEmpresa, idMonitoracao) {
    return $axios.get(`${URL_API}/${siglaEmpresa}/${idMonitoracao}/imagens`)
  },

  getImagem(siglaEmpresa, idMonitoracao, sequencia) {
    return $axios.get(
      `${URL_API}${siglaEmpresa}/${idMonitoracao}/${sequencia}/imagens`
    )
  },

  createAnexo(siglaEmpresa, idMonitoracao, token, formData) {
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `${URL_API}/${siglaEmpresa}/${idMonitoracao}/imagens`,
      formData
    )
  },

  deleteImagem(siglaEmpresa, idMonitoracao, seqAnexo) {
    return $axios.delete(
      `${URL_API}/${siglaEmpresa}/${idMonitoracao}/imagens/${seqAnexo}`
    )
  },

  findHistoricos(siglaEmpresa, idMonitoracao) {
    return $axios.get(`${URL_API}/${siglaEmpresa}/${idMonitoracao}/historicos`)
  },

  findCiclos(siglaEmpresa, idMonitoracao, idElemento, fase) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/${idMonitoracao}/ciclos/${idElemento}?fase=${fase}`
    )
  },

  findAnexos(siglaEmpresa, idMonitoracao) {
    return $axios.get(`${URL_API}/${siglaEmpresa}/${idMonitoracao}/anexos`)
  },

  getAnexo(siglaEmpresa, idMonitoracao, sequencia) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/${idMonitoracao}/anexos/${sequencia}`
    )
  },

  saveAnexo(siglaEmpresa, idMonitoracao, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `${URL_API}/${siglaEmpresa}/${idMonitoracao}/anexos`,
      formData
    )
  },

  deleteAnexo(siglaEmpresa, idMonitoracao, seqAnexo) {
    return $axios.delete(
      `${URL_API}/${siglaEmpresa}/${idMonitoracao}/anexos/${seqAnexo}`
    )
  },

  downloadAnexo(empresa, modulo, uuid) {
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.get(
      `/downloads/${empresa}/anexos/${modulo}/${uuid}`,
      requestCfg
    )
  },

  executeImportacao(siglaEmpresa, formData) {
    $axios.setHeader('Content-Type', 'multipart/form-data', ['post'])

    const call = $axios.post(`/importacoes/${siglaEmpresa}/GAC`, formData, {
      timeout: 0,
    })
    return call
  },

  exportarPlanilha(siglaEmpresa, tipoAtivo, filtro) {
    let parms = ''
    let sizeFase = 0

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue

          // Fase Monitoração
          if (key === 'faseMonitoracao') {
            if (filtro.faseMonitoracao.length > 0) {
              if (filtro.faseMonitoracao.length > sizeFase) {
                for (const fase of filtro.faseMonitoracao) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + fase
                  sizeFase++
                }
              }
            }
          }

          if (key !== 'faseMonitoracao') {
            if (filtro[key] !== null) {
              if (parms !== '') parms += '&'
              parms += key + '=' + filtro[key]
            }
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.post(
      `${URL_API}/${siglaEmpresa}/${tipoAtivo}/exportacao${parms}`,
      null,
      { responseType: 'blob' }
    )
  },

  downloadRelatorio(siglaEmpresa, tipoAtivo, filtro) {
    let parms = ''
    if (filtro.length > 0) {
      for (const key of filtro) {
        if (parms !== '') parms += '&'
        parms += 'fasesMonitoracao' + '=' + key.faseMonitoracao
      }

      if (parms !== '') parms = '?' + parms
    }

    return $axios.get(
      `/cadastros/sigaco/${siglaEmpresa}/fasesMonitoracao/relatorios/${tipoAtivo}${parms}`
    )
  },

  findImportacoes(siglaEmpresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`${URL_API}/importacoes/${siglaEmpresa}${parms}`)
  },

  getImportacaoById(siglaEmpresa, fase, seqImportacao) {
    return $axios.get(
      `${URL_API}/importacoes/${siglaEmpresa}/${fase}/${seqImportacao}`
    )
  },

  aprovar(siglaEmpresa, idFase, sequencia) {
    return $axios.post(
      `${URL_API}/importacoes/${siglaEmpresa}/${idFase}/${sequencia}/aprovar`
    )
  },

  publicar(siglaEmpresa, idFase, sequencia) {
    return $axios.post(
      `${URL_API}/importacoes/${siglaEmpresa}/${idFase}/${sequencia}/publicar`
    )
  },

  reprovarImportacao(siglaEmpresa, idFase, sequencia) {
    return $axios.post(
      `${URL_API}/importacoes/${siglaEmpresa}/${idFase}/${sequencia}/reprovarImportacao`
    )
  },
  reprovarPublicacao(siglaEmpresa, idFase, sequencia) {
    return $axios.post(
      `${URL_API}/importacoes/${siglaEmpresa}/${idFase}/${sequencia}/reprovarPublicacao`
    )
  },

  getDashboard(siglaEmpresa, tipoAtivo, filtro) {
    let parms = ''
    let sizeFase = 0

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue

          // Fase Monitoração
          if (key === 'faseMonitoracao') {
            if (filtro.faseMonitoracao.length > 0) {
              if (filtro.faseMonitoracao.length > sizeFase) {
                for (const fase of filtro.faseMonitoracao) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + fase
                  sizeFase++
                }
              }
            }
          }

          if (key !== 'faseMonitoracao') {
            if (filtro[key] !== null) {
              if (parms !== '') parms += '&'
              parms += key + '=' + filtro[key]
            }
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(
      `${URL_API}/${siglaEmpresa}/dashboards/${tipoAtivo}${parms}`
    )
  },

  getDashboardItens(
    siglaEmpresa,
    tipoAtivo,
    tipoGrafico,
    serieName,
    itemName,
    filtro,
    tipoChart
  ) {
    let parms = `tipoGrafico=${tipoGrafico}&serieName=${serieName}&itemName=${itemName}`
    let sizeFase = 0

    if (tipoChart) parms += `&subTipo=${tipoChart}`

    console.log('filtro=>', filtro)

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue

          // Fase Monitoração
          if (key === 'faseMonitoracao') {
            if (filtro.faseMonitoracao.length > 0) {
              if (filtro.faseMonitoracao.length > sizeFase) {
                for (const fase of filtro.faseMonitoracao) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + fase
                  sizeFase++
                }
              }
            }
          }

          if (key !== 'faseMonitoracao') {
            if (filtro[key] !== null) {
              if (parms !== '') parms += '&'
              parms += key + '=' + filtro[key]
            }
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(
      `${URL_API}/${siglaEmpresa}/dashboards/${tipoAtivo}/itens${parms}`
    )
  },

  getDashboardCiclos(siglaEmpresa, tipoAtivo, idElemento) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/dashboards/${tipoAtivo}/ciclos/${idElemento}`
    )
  },
})
