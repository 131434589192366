export default ($axios) => ({
  find(siglaEmpresa, search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/entidades/${siglaEmpresa}`)
    } else {
      return $axios.get(`/entidades/${siglaEmpresa}?search=${search}`)
    }
  },
  getById(siglaEmpresa, idEntidade) {
    return $axios.get(`/entidades/${siglaEmpresa}/${idEntidade}`)
  },
  save(entidade) {
    return $axios.post('/entidades', entidade)
  },
  delete(siglaEmpresa, idEntidade) {
    return $axios.delete(`/entidades/${siglaEmpresa}/${idEntidade}`)
  },

  // Setores
  saveSetor(setor) {
    return $axios.post('/entidades/setores', setor)
  },
  getSetoresByEntidade(siglaEmpresa, idEntidade) {
    return $axios.get(`/entidades/${siglaEmpresa}/${idEntidade}/setores`)
  },
  deleteSetor(empresa, idSetor) {
    return $axios.delete(`/entidades/${empresa}/${idSetor}/setores`)
  },

  // Responsáveis
  saveResponsavel(responsavel) {
    return $axios.post('/entidades/responsaveisSetores', responsavel)
  },
  findResponsaveisBySetor(siglaEmpresa, idEntidade) {
    return $axios.get(
      `/entidades/${siglaEmpresa}/${idEntidade}/responsaveisSetores`
    )
  },
  deleteResponsavel(siglaEmpresa, idSetor, sequencia) {
    return $axios.delete(
      `/entidades/${siglaEmpresa}/${idSetor}/responsaveisSetores/${sequencia}`
    )
  },
})
