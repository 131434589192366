export default ($axios) => ({
  findCheckList(
    siglaEmpresa,
    modulo,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'id.idCheckList'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection

    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/checkLists/lancamentos/filtro/${siglaEmpresa}/${modulo}?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
      payload
    )
  },

  getCheckListById(siglaEmpresa, idCheckList, modulo) {
    return $axios.get(
      `/checkLists/lancamentos/${siglaEmpresa}/${idCheckList}/${modulo}`
    )
  },

  findHistoricos(idCheckList) {
    return $axios.get(`/checkLists/${idCheckList}/historicos`)
  },

  saveHistorico(siglaEmpresa, idCheckList, modulo, historico) {
    const formData = new FormData()

    if (historico !== undefined)
      formData.append('descricao', historico.descricao + '')

    return $axios.post(
      `/checkLists/${siglaEmpresa}/${idCheckList}/${modulo}/historicos`,
      formData
    )
  },

  saveCheckList(siglaEmpresa, payload) {
    payload.empresa = undefined
    payload.ficha = undefined
    payload.rodovia = undefined
    payload.cidade = undefined
    payload.obra = undefined
    payload.supervisorAmbiental = undefined
    payload.coordenadorAmbiental = undefined
    payload.coordenadorObra = undefined
    payload.equipeConcessao = undefined
    payload.equipeAmbiental = undefined
    payload.equipeEmpreiteira = undefined
    payload.acumulado = undefined

    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(`/checkLists/lancamentos/${siglaEmpresa}`, payload)
  },

  deleteCheckList(siglaEmpresa, idCheckList) {
    return $axios.delete(
      `/checkLists/lancamentos/${siglaEmpresa}/${idCheckList}`
    )
  },

  findFichas(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/checkLists/fichas`)
    } else {
      return $axios.get(`/checkLists/fichas?search=${search}`)
    }
  },

  getFichaById(idFicha) {
    return $axios.get(`/checkLists/fichas/itens/${idFicha}`)
  },

  getFichaBySequencia(idFicha, sequencia) {
    return $axios.get(`/checkLists/fichas/itens/${idFicha}/${sequencia}`)
  },

  deleteFicha(idEstruturaFicha) {
    return $axios.delete(`/checkLists/fichas/${idEstruturaFicha}`)
  },

  saveFicha(checkList) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(`/checkLists/fichas`, checkList)
  },

  findEstruturas(filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/checkLists/estruturas${parms}`)
  },

  findEstruturasPai(filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/checkLists/estruturasPai${parms}`)
  },

  getEstruturaById(idEstrutura) {
    return $axios.get(`/checkLists/estruturas/${idEstrutura}`)
  },

  deleteEstrutura(idEstrutura) {
    return $axios.delete(`/checkLists/estruturas/${idEstrutura}`)
  },

  saveEstrutura(checkList) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(`/checkLists/estruturas`, checkList)
  },

  getItensByChecklist(siglaEmpresa, idCheckList, modulo) {
    return $axios.get(
      `/checkLists/lancamentos/itens/${siglaEmpresa}/${idCheckList}/${modulo}`
    )
  },
  getItensBySequencia(siglaEmpresa, idCheckList, sequencia) {
    return $axios.get(
      `/checkLists/lancamentos/itens/${siglaEmpresa}/${idCheckList}/${sequencia}`
    )
  },

  saveAnexo(siglaEmpresa, idCheckList, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/checkLists/${siglaEmpresa}/lancamentos/${idCheckList}/anexos`,
      formData
    )
  },

  deleteAnexo(siglaEmpresa, idCheckList, seqAnexo) {
    return $axios.delete(
      `/checkLists/lancamentos/anexos/${siglaEmpresa}/${idCheckList}/${seqAnexo}`
      // /lancamentos/anexos/{empresa}/{idCheckList}/{sequencia}saveAnexo
    )
  },

  editComentarioAnexo(siglaEmpresa, idCheckList, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/checkLists/${siglaEmpresa}/${idCheckList}/anexos/comentarios`,
      payload
    )
  },

  downloadCheckListPdf(siglaEmpresa, idCheckList, tipoMapa = 'S') {
    return $axios.get(
      `/checkLists/${siglaEmpresa}/exportacaoPdf/${idCheckList}?tipoMapa=${tipoMapa}`,
      { responseType: 'blob' }
    )
  },

  downloadCheckListXls(siglaEmpresa, idCheckList) {
    return $axios.get(
      `/checkLists/${siglaEmpresa}/exportacaoXls/${idCheckList}`,
      { responseType: 'blob' }
    )
  },

  relatorioChecklists(siglaEmpresa, filtro, modulo) {
    return $axios.post(
      `/checkLists/${siglaEmpresa}/exportacaoExcel/${modulo}`,
      filtro,
      {
        responseType: 'blob',
      }
    )
  },

  findAnexos(siglaEmpresa, idChecklist) {
    return $axios.get(`/checkLists/${siglaEmpresa}/${idChecklist}/anexos`)
  },

  findAssinatura(siglaEmpresa, idCheckList, modulo) {
    return $axios.get(
      `/checkLists/lancamentos/assinaturas/${siglaEmpresa}/${idCheckList}/${modulo}`
    )
  },

  girarImagem(siglaEmpresa, idCheckList, idAnexo, angulo) {
    return $axios.post(
      `/checkLists/${siglaEmpresa}/${idCheckList}/anexos/girar/${idAnexo}/${angulo}`
    )
  },

  findRacs(siglaEmpresa, idCheckList, modulo) {
    return $axios.get(
      `/checkLists/${siglaEmpresa}/${idCheckList}/${modulo}/racs`
    )
  },

  gerarRac(
    siglaEmpresa,
    idCheckList,
    modulo,
    idTipoRac,
    prazo,
    multiplasRac,
    items
  ) {
    return $axios.post(
      `/checkLists/${siglaEmpresa}/${idCheckList}/${modulo}/racs?idTipoRac=${idTipoRac}&prazo=${prazo}&multiplasRac=${multiplasRac}`,
      items
    )
  },
})
