export default ($axios) => ({
  // Licenças
  findLicencas(empresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/licencas/${empresa}${parms}`)
  },

  saveLicenca(empresa, licenca) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/licencas/${empresa}`, licenca)
  },
  deleteLicenca(empresa, idLicenca) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.delete(`/licencas/${empresa}/${idLicenca}`)
  },
  getLicencaById(empresa, idLicenca) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/licencas/${empresa}/${idLicenca}`)
  },

  // Obras
  saveLicencasObras(empresa, idLicenca, obra) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/licencas/${empresa}/${idLicenca}/obras`, obra)
  },

  deleteLicencasObras(empresa, idLicenca, idObra) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.delete(`/licencas/${empresa}/${idLicenca}/obras/${idObra}`)
  },

  // find obras pelas licenças
  getLicencasObrasById(empresa, idLicenca) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/licencas/${empresa}/${idLicenca}/obras`)
  },

  getObrasById(empresa, idLicenca, idObra) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/licencas/${empresa}/${idLicenca}/obras/${idObra}`)
  },

  // CONDICIONANTES
  saveCondicionante(empresa, idLicenca, condicionante) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/licencas/${empresa}/${idLicenca}/condicionantes`,
      condicionante
    )
  },

  getLicencaCondicionateById(empresa, idLicenca) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/licencas/${empresa}/${idLicenca}/condicionantes`)
  },

  getCondicionateById(empresa, idCondicionante) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/licencas/${empresa}/condicionantes/${idCondicionante}`)
  },

  deleteCondicionate(empresa, idCondicionante) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.delete(
      `/licencas/${empresa}/condicionantes/${idCondicionante}`
    )
  },

  // Documentos - CONDICIONANTES
  saveAnexoCondicionante(empresa, idLicenca, idCondicionante, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/documentos`,
      formData
    )
  },

  findDocumentosCondicionante(empresa, idLicenca, idCondicionante) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/documentos`
    )
  },

   getDocumentoCondicionateById(empresa, idCondicionante) {
    $axios.setHeader('Content-Type', 'application/json')

     return $axios.get(`/licencas/${empresa}/condicionantes/${idCondicionante}`)
   },

   deleteDocumentoCondicionate(empresa, idLicenca, idCondicionante, sequencia) {
    $axios.setHeader('Content-Type', 'application/json')

     return $axios.delete(`/licencas/${empresa}/${idLicenca}/${idCondicionante}/documentos/${sequencia}`)
   },

  // Notas
  findNotas(empresa, idLicenca, idCondicionante) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/notas`
    )
  },

  saveNota(empresa, idLicenca, idCondicionante, nota) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/notas`,
      nota
    )
  },

  getNotaById(empresa, idLicenca, idCondicionante, idNota) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/notas/${idNota}`
    )
  },

  deleteNota(empresa, idLicenca, idCondicionante, idNota) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.delete(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/notas/${idNota}`
    )
  },

  // Documentos
  findAnexosLicencaById(empresa, idLicenca) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/licencas/${empresa}/${idLicenca}/documentos`)
  },

  saveAnexoLicenca(empresa, idLicenca, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(`/licencas/${empresa}/${idLicenca}/documentos`, formData)
  },

  deleteAnexoLicenca(empresa, idLicenca, seqArquivo) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.delete(`/licencas/${empresa}/${idLicenca}/documentos/${seqArquivo}`)
  },

  downloadAnexo(empresa, modulo, uuid) {
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.get(
      `/downloads/${empresa}/anexos/${modulo}/${uuid}`,
      requestCfg
    )
  },

  // Correspondência
  findCorrespondenciaByLicenca(empresa, idCorrespondencia, idLicenca) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(
      `/correspondencias/${empresa}/${idCorrespondencia}/${idLicenca}/relacoesLicenca`
    )
  },

  // Cadastros
  // --------------------------------------------------------------
  // Status Licenca
  findStatusLicencas(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/statusLicenca`)
    } else {
      return $axios.get(`/cadastros/amb/statusLicenca?search=${search}`)
    }
  },

  getStatusLicencas(idStatusLicenca) {
    return $axios.get(`/cadastros/amb/statusLicenca/${idStatusLicenca}`)
  },

  saveStatusLicencas(status) {
    return $axios.post(`/cadastros/amb/statusLicenca`, status)
  },

  deleteStatusLicencas(idStatus) {
    return $axios.delete(`/cadastros/amb/statusLicenca/${idStatus}`)
  },

  // Caráter das Condicionantes
  findCaraterCondicionates(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/caraterCondicionante`)
    } else {
      return $axios.get(`/cadastros/amb/caraterCondicionante?search=${search}`)
    }
  },

  getCaraterCondicionates(idCarater) {
    return $axios.get(`/cadastros/amb/caraterCondicionante/${idCarater}`)
  },

  saveCaraterCondicionates(carater) {
    return $axios.post(`/cadastros/amb/caraterCondicionante`, carater)
  },

  deleteCaraterCondicionates(idCarater) {
    return $axios.delete(`/cadastros/amb/caraterCondicionante/${idCarater}`)
  },

  // Status das Condicionantes
  findStatusCondicionante(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/statusCondicionante`)
    } else {
      return $axios.get(`/cadastros/amb/statusCondicionante/?search=${search}`)
    }
  },

  getStatusCondicionante(idStatus) {
    return $axios.get(`/cadastros/amb/statusCondicionante/${idStatus}`)
  },

  saveStatusCondicionante(status) {
    return $axios.post(`/cadastros/amb/statusCondicionante`, status)
  },

  deleteStatusCondicionante(idStatus) {
    return $axios.delete(`/cadastros/amb/statusCondicionante/${idStatus}`)
  },

   // Plano de Ação
   savePlanoAcao(empresa, idCondicionante, planoAcao) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/licencas/${empresa}/${idCondicionante}/planosAcao`,
      planoAcao
    )
  },
  saveDocumentosPlanoAcao(empresa, idLicenca, idCondicionante, idPlanoAcao, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/planosAcao/${idPlanoAcao}/documentos`,
      formData
    )
  },
  findDocumentosPlanoAcao(empresa, idLicenca, idCondicionante, idPlanoAcao) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.get(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/planosAcao/${idPlanoAcao}/documentos`
    )
  },
  getPlanosAcao(empresa, idLicenca, idCondicionante) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.get(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/planosAcao`
    )
  },
  deleteDocumentoPlanoAcao(empresa, idLicenca, idCondicionante, idPlano) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.delete(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/planosAcao/${idPlano}`
    )
  },
  getPlanoAcaoById(empresa, idLicenca, idCondicionante, idPlano) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.get(
      `/licencas/${empresa}/${idLicenca}/${idCondicionante}/planosAcao/${idPlano}`
    )
  },

  // Prioridade do Plano de Ação
  findPrioridade(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/${empresa}/prioridadesPlanoAcao`)
    } else {
      return $axios.get(
        `/cadastros/amb/${empresa}/prioridadesPlanoAcao/?search=${search}`
      )
    }
  },

  getPrioridade(empresa, idPlanoAcao) {
    return $axios.get(
      `/cadastros/amb/${empresa}/prioridadePlanoAcao/${idPlanoAcao}`
    )
  },

  savePrioridade(prioridade) {
    return $axios.post(`/cadastros/amb/prioridadePlanoAcao`, prioridade)
  },

  deletePrioridade(empresa, idPlanoAcao) {
    return $axios.delete(
      `/cadastros/amb/${empresa}/prioridadePlanoAcao/${idPlanoAcao}`
    )
  },

  // Tipos de Plano de Ação
  findTipoPlanoAcao(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/${empresa}/tiposPlanoAcao`)
    } else {
      return $axios.get(
        `/cadastros/amb/${empresa}/tiposPlanoAcao?search=${search}`
      )
    }
  },

  getTipoPlanoAcao(empresa, idTipoPlanoAcao) {
    return $axios.get(
      `/cadastros/amb/${empresa}/tipoPlanoAcao/${idTipoPlanoAcao}`
    )
  },

  saveTipoPlanoAcao(plano) {
    return $axios.post(`/cadastros/amb/tipoPlanoAcao`, plano)
  },

  deleteTipoPlanoAcao(empresa, idTipoPlanoAcao) {
    return $axios.delete(
      `/cadastros/amb/${empresa}/tipoPlanoAcao/${idTipoPlanoAcao}`
    )
  },

  // Status do Plano de Ação
  findStatusPlanoAcao(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/${empresa}/statusPlanoAcao`)
    } else {
      return $axios.get(
        `/cadastros/amb/${empresa}/statusPlanoAcao?search=${search}`
      )
    }
  },

  getStatusPlanoAcao(empresa, idStatusPlanoAcao) {
    return $axios.get(
      `/cadastros/amb/${empresa}/statusPlanoAcao/${idStatusPlanoAcao}`
    )
  },

  saveStatusPlanoAcao(status) {
    return $axios.post(`/cadastros/amb/statusPlanoAcao`, status)
  },

  deleteStatusPlanoAcao(empresa, idStatusPlanoAcao) {
    return $axios.delete(
      `/cadastros/amb/${empresa}/statusPlanoAcao/${idStatusPlanoAcao}`
    )
  },
})
