export default ($axios) => ({
  findAnaliseAmbiental(
    siglaEmpresa,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'numero'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection

    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/analises/${siglaEmpresa}/filtros?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
      payload
    )
  },


  getAnaliseAmbientalById(siglaEmpresa, idAnaliseAmbiental) {
    return $axios.get(`/analises/${siglaEmpresa}/${idAnaliseAmbiental}`)
  },

  saveAnaliseAmbiental(siglaEmpresa, analise) {
    const formData = new FormData()

    analise.cidade = null
    analise.empresa = null
    analise.obra = null
    analise.rodovia = null
    analise.coordenadorObra = null
    analise.equipeConcessao = null
    analise.equipeEmpreiteira = null
    analise.equipeAmbiental = null

    if (analise !== undefined) {
      if (analise.anexos !== undefined) {
        const anexosRaw = []
        for (const anexo of analise.anexos) {
          formData.append('filesAnexos', anexo.file)
          anexosRaw.push({
            comentario: anexo.comentario,
            anexo: {
              nome: anexo.file.name,
            },
          })
        }

        formData.append('anexos', JSON.stringify(anexosRaw))
        analise.anexos = null
      }

      formData.append(
        'analise',
        JSON.stringify(analise, (key, value) => {
          if (value !== null) return value
        })
      )


    }

    return $axios.post(`/analises/${siglaEmpresa}`, formData)
  },

  deleteAnaliseAmbiental(siglaEmpresa, idAnaliseAmbiental) {
    return $axios.delete(`/analises/${siglaEmpresa}/${idAnaliseAmbiental}`)
  },

  createAnexo(siglaEmpresa, idAnaliseAmbiental, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/anexos`,
      formData
    )
  },

  editComentarioAnexo(siglaEmpresa, idAnaliseAmbiental, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/anexos/comentarios`,
      payload
    )
  },

  findAnexos(siglaEmpresa, idAnaliseAmbiental) {
    return $axios.get(`/analises/${siglaEmpresa}/${idAnaliseAmbiental}/anexos`)
  },

  deleteAnexo(siglaEmpresa, idAnaliseAmbiental, seqAnexo) {
    return $axios.delete(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/anexos/${seqAnexo}`
    )
  },

  girarImagem(siglaEmpresa, idAnaliseAmbiental, idAnexo, angulo) {
    return $axios.post(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/anexos/girar/${idAnexo}/${angulo}`
    )
  },

  findOcorrenciasAnalise(siglaEmpresa, idAnaliseAmbiental) {
    return $axios.get(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/ocorrencias`
    )
  },

  findAprovacoes(siglaEmpresa, idAnaliseAmbiental) {
    return $axios.get(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/aprovacoes`
    )
  },

  findHistoricos(siglaEmpresa, idAnaliseAmbiental) {
    return $axios.get(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/historicos`
    )
  },

  saveHistorico(siglaEmpresa, idAnaliseAmbiental, historico) {
    const formData = new FormData()

    if (historico !== undefined)
      formData.append('descricao', historico.descricao + '')

    return $axios.post(
      `/analises/${siglaEmpresa}/${idAnaliseAmbiental}/historicos`,
      formData
    )
  },

  downloadPdf(empresa, idAnaliseAmbiental, tipoMapa = 'S') {
    return $axios.get(
      `/analises/${empresa}/exportacaoPdf/${idAnaliseAmbiental}?tipoMapa=${tipoMapa}`,
      {
        responseType: 'blob',
      }
    )
  },
  downloadExcel(empresa, filter) {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.post(
      `/analises/${empresa}/exportacaoExcel`,
      filter,
      requestCfg
    )
  },

  executeEvento(empresa, idAnaliseAmbiental, tipoEvento, paramsPayload = {}) {
    $axios.setHeader('Content-Type', 'application/json')
    const { seqAprovacao, justificativa, perfilReprovar } = paramsPayload

    let parms = ''

    if (Object.keys(paramsPayload).length > 0) {
      if (seqAprovacao !== undefined) parms += '&seqAprovacao=' + seqAprovacao
      if (justificativa !== undefined) parms += '&descricao=' + justificativa
      if (perfilReprovar !== undefined)
        parms += '&perfilReprovar=' + perfilReprovar

      parms = '?' + parms.slice(1)
    }

    return $axios.post(
      `/analises/${empresa}/${idAnaliseAmbiental}/eventos/${tipoEvento}${parms}`
    )
  },
})
